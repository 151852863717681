import { addTeam } from "../run";

addTeam({
    setupModules: [
        {
            moduleId: "lesson:init-module",
            enforce: "post",
            loader(importWithRetry) {
                return importWithRetry("@manabie-com/mfe/lesson-init-module");
            },
            onError(_err: unknown) {},
        },
    ],
    teamName: "lesson",
    registerApps(register, importWithRetry, appOptions) {
        register(() => {
            return {
                name: "lesson-main",
                app: () => importWithRetry("@manabie-com/mfe/lesson-main"),
                activeOnPath: (location) => {
                    return new RegExp(/^\/lesson(\/.*)?$/).test(location.pathname);
                },
                renderToSelector: appOptions.selectors.authenticated,
                basePath: "/lesson",
            };
        });
    },
});
