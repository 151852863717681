import { addTeam } from "../run";

addTeam({
    setupModules: [
        {
            moduleId: "calendar:init-module",
            enforce: "post",
            loader(importWithRetry) {
                return importWithRetry("@manabie-com/mfe/calendar-init-module");
            },
            onError(_err: unknown) {},
        },
    ],
    teamName: "calendar",
    registerApps(register, importWithRetry, appOptions) {
        register(() => {
            return {
                name: "calendar-main",
                app: () => importWithRetry("@manabie-com/mfe/calendar-main"),
                activeOnPath: (location) => {
                    return new RegExp(/^\/calendar(\/.*)?$/).test(location.pathname);
                },
                renderToSelector: appOptions.selectors.authenticated,
                basePath: "/calendar",
            };
        });
    },
});
