import type { IEmitterEvent } from "../mana-event-emitter-interfaces";
import type { UserAPIs } from "../user-APIs-types";
import type { IManaSidebar } from "./sidebar";

import type { ManaEventEmitter } from "@manabie-com/event-emitter";
import type { ManaFeatureController } from "@manabie-com/feature-controller";
import type { ManaFeatureSettingConfiguration } from "@manabie-com/feature-setting-configuration";
import type ManaTaskRunner from "@manabie-com/mana-task-runner";
import type { PjOwner } from "src/only-for-temporary/org-apis/org-APIs-types";

interface GlobalOptions {
    getRoot: () => IManaGlobal;
}

export interface IManaGlobal {
    isMultiTenant: boolean;
    defaultPjOwner: PjOwner;
    getManaEventEmitter: () => ManaEventEmitter<IEmitterEvent>;
    getManaSidebar: () => IManaSidebar;
    getManaTaskRunner: () => ManaTaskRunner;
    getManaFeatureSettings: <
        T extends Record<string, any> = never,
    >() => ManaFeatureSettingConfiguration<T>;
    getFeatureFlagController: <T = never>() => ManaFeatureController<T>;
    getUserAPIs: () => UserAPIs;
}

interface ManaGlobalReturn {
    eventEmitter: () => ManaEventEmitter<IEmitterEvent>;
    sidebar: () => IManaSidebar;
    taskRunner: () => ManaTaskRunner;
    featureSettings: <
        T extends Record<string, any> = never,
    >() => ManaFeatureSettingConfiguration<T>;
    featureFlagController: <T = never>() => ManaFeatureController<T>;
    userAPIs: () => UserAPIs;
}

export function createManaGlobals({ getRoot }: GlobalOptions): ManaGlobalReturn {
    return {
        eventEmitter() {
            return getRoot().getManaEventEmitter();
        },
        sidebar() {
            return getRoot().getManaSidebar();
        },
        taskRunner() {
            return getRoot().getManaTaskRunner();
        },
        featureSettings<T extends Record<string, any> = never>() {
            return getRoot().getManaFeatureSettings<T>();
        },
        featureFlagController<T = never>() {
            return getRoot().getFeatureFlagController<T>();
        },
        userAPIs: () => {
            return getRoot().getUserAPIs();
        },
    };
}

export const manaGlobals = createManaGlobals({
    getRoot() {
        // default to window.__MANA__
        return window.__MANA__;
    },
});
