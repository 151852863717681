// Inject your team instance into the root `window.__MANA__`
// Restriction: always in object style

import type { InjectionKey, RootInjector } from "../core/core-types";

export function createInjector(root: Record<string, unknown>): RootInjector {
    return {
        inject<T extends Record<string & keyof T, object>>(key: InjectionKey<T>, value: T) {
            const keyAsString = key as string;
            if (root[keyAsString]) {
                throw new Error(
                    `key: ${key} is existed, please inject another key or your script is loaded 2 times?`
                );
            }
            root[keyAsString] = value;
        },
    };
}
