import { addTeam } from "../run";

addTeam({
    setupModules: [
        {
            moduleId: "syllabus:init-module",
            enforce: "post",
            loader(importWithRetry) {
                return importWithRetry("@manabie-com/mfe/syllabus-init-module");
            },
            onError(_err: unknown) {},
        },
    ],
    teamName: "syllabus",
    registerApps(register, importWithRetry, appOptions) {
        register(() => {
            return {
                name: "syllabus-main",
                app: () => importWithRetry("@manabie-com/mfe/syllabus-main"),
                activeOnPath: (location) => {
                    return new RegExp(/^\/syllabus(\/.*)?$/).test(location.pathname);
                },
                renderToSelector: appOptions.selectors.authenticated,
                basePath: "/syllabus",
                isPreferred: import.meta.env.VITE_PJ_OWNER === "JPREP",
            };
        });
    },
});
