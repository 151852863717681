import { createBrowserHistory } from "history";
import { createManaEventEmitter } from "src/only-for-temporary/mana-event-emitter/mana-event-emitter";
import { createSidebarMethods } from "src/only-for-temporary/mana-globals/sidebar";

import { manaGlobals } from "src/only-for-temporary/mana-globals/mana-globals";
import { createRootShell } from "../core/bootstrap";
import type { AppDOMSelector, YourTeamProps } from "../core/core-types";
import { createManaTaskRunner } from "../core/mana-task-runner";
import { createInjector } from "../packages/injector";

// global route history instance to share the history state across app
const routeHistory = createBrowserHistory({
    getUserConfirmation: (message, confirm) => {
        manaGlobals.eventEmitter().publish("routing:user-confirm-prompt", {
            message,
            confirm,
        });
    },
});

const { addTeam, startApp } = createRootShell<AppDOMSelector, YourTeamProps>({
    selectors: {
        main: "#main",
        authenticated: "#main",
        unauthenticated: "#unauthenticated",
        appbar: "#appbar",
        sidebar: "#sidebar",
        snackbar: "#snackbar",
        taskbar: "#taskbar",
    } as const,
    getInjector: () => {
        if (!window.__MANA__) {
            window.__MANA__ = {} as any;
        }
        return createInjector(window.__MANA__ as unknown as Record<string, unknown>);
    },
    additionalProps: {
        routeHistory,
    },
    hooks: {
        onInjectorReady: (injector) => {
            // setup required basic modules
            const eventEmitter = createManaEventEmitter();
            injector.inject("getManaEventEmitter", () => eventEmitter);

            const sidebarMethods = createSidebarMethods();
            injector.inject("getManaSidebar", () => sidebarMethods);

            const taskRunner = createManaTaskRunner();
            injector.inject("getManaTaskRunner", () => taskRunner);
        },
    },
});

export { addTeam, startApp };
