import { addTeam } from "../run";

function renderOnAuth(pathname: string) {
    return (
        !pathname.startsWith("/login") &&
        !pathname.startsWith("/forgot") &&
        !pathname.startsWith("/login-tenant") &&
        !pathname.startsWith("/forgot-tenant") &&
        !pathname.includes("/reset-password") &&
        !pathname.startsWith("/_auth") &&
        !pathname.includes("/page-not-found")
    );
}

addTeam({
    setupModules: [
        {
            moduleId: "architecture:core-deps",
            enforce: "pre",
            loader(importWithRetry) {
                return importWithRetry("@manabie-com/mfe/architecture-core-deps");
            },
            onError(_err: unknown) {},
        },
        {
            moduleId: "architecture:init-module",
            enforce: "post",
            loader(importWithRetry) {
                return importWithRetry("@manabie-com/mfe/architecture-init-module");
            },
            onError(_err: unknown) {},
        },
    ],
    teamName: "architecture",
    registerApps(register, importWithRetry, appOptions) {
        register(() => {
            return {
                name: "architecture-snackbar",
                app: async () => {
                    return (await importWithRetry("@manabie-com/mfe/architecture-layout"))
                        .snackbarReactLifeCycle;
                },
                activeOnPath: () => {
                    return true; //snackbar is always rendered
                },
                renderToSelector: appOptions.selectors.snackbar,
                basePath: "/",
            };
        });
        register(() => {
            return {
                name: "architecture-sidebar",
                app: async () => {
                    return (await importWithRetry("@manabie-com/mfe/architecture-layout"))
                        .sidebarReactLifeCycle;
                },
                activeOnPath: (location) => {
                    return renderOnAuth(location.pathname);
                },
                renderToSelector: appOptions.selectors.sidebar,
                basePath: "/",
            };
        });

        register(() => {
            return {
                name: "architecture-not-found",
                app: async () => {
                    return (await importWithRetry("@manabie-com/mfe/architecture-layout"))
                        .notFoundReactLifeCycle;
                },
                activeOnPath: (location) => {
                    return location.pathname.includes("/page-not-found");
                },
                renderToSelector: appOptions.selectors.main,
                basePath: "/",
            };
        });

        register(() => {
            return {
                name: "architecture-main",
                app: () => importWithRetry("@manabie-com/mfe/architecture-main"),
                activeOnPath: (location) => {
                    return new RegExp(/^\/architecture(\/.*)?$/).test(location.pathname);
                },
                renderToSelector: appOptions.selectors.main,
                basePath: "/architecture",
            };
        });

        register(() => {
            return {
                name: "architecture-notification-taskbar",
                app: async () => {
                    return (await importWithRetry("@manabie-com/mfe/architecture-layout"))
                        .notificationTaskBarReactLifeCycle;
                },
                activeOnPath: () => {
                    return true;
                },
                renderToSelector: appOptions.selectors.taskbar,
                basePath: "/",
            };
        });
    },
});
