import { addTeam } from "../run";

addTeam({
    setupModules: [
        {
            moduleId: "adobo:init-module",
            enforce: "post",
            loader(importWithRetry) {
                return importWithRetry("@manabie-com/mfe/adobo-init-module");
            },
            onError(_err: unknown) {},
        },
    ],
    teamName: "adobo",
    registerApps(register, importWithRetry, appOptions) {
        register(() => {
            return {
                name: "adobo-entry-exit",
                dependOn: "architecture-layout", //TODO: @vctqs1 will do this later in https://manabie.atlassian.net/browse/LT-45660
                app: () => importWithRetry("@manabie-com/mfe/adobo-main"),
                activeOnPath: (location) => {
                    return new RegExp(/^\/entry-exit(\/.*)?$/).test(location.pathname);
                },
                renderToSelector: appOptions.selectors.authenticated,
                basePath: "/entry-exit",
            };
        });

        register(() => {
            return {
                name: "adobo-invoice",
                app: () => importWithRetry("@manabie-com/mfe/adobo-main"),
                activeOnPath: (location) => {
                    return new RegExp(/^\/invoice(\/.*)?$/).test(location.pathname);
                },
                renderToSelector: appOptions.selectors.authenticated,
                basePath: "/invoice",
            };
        });
    },
});
