import { addTeam } from "../run";

// add 1-users to force load user's pre modules before architecture's pre modules
addTeam({
    setupModules: [
        {
            moduleId: "user:auth-apis",
            enforce: "pre",
            async loader(importWithRetry) {
                return (await importWithRetry("@manabie-com/mfe/user-init-module")).userAuthAPIs;
            },
            onError(_err: unknown) {},
        },
        {
            moduleId: "user:init-module",
            enforce: "post",
            async loader(importWithRetry) {
                return (await importWithRetry("@manabie-com/mfe/user-init-module")).userInitModules;
            },
            onError(_err: unknown) {},
        },
    ],
    teamName: "user",
    registerApps(register, importWithRetry, appOptions) {
        register(() => {
            return {
                name: "user-main",
                app: () => {
                    return importWithRetry("@manabie-com/mfe/user-main");
                },
                activeOnPath: (location) => {
                    return new RegExp(/^\/user(\/.*)?$/).test(location.pathname);
                },
                renderToSelector: appOptions.selectors.authenticated,
                basePath: "/user",
                isPreferred: true,
            };
        });

        register(() => {
            return {
                name: "user-auth",
                app: () => importWithRetry("@manabie-com/mfe/user-auth"),
                activeOnPath: (location) => {
                    return (
                        location.pathname.startsWith("/login") ||
                        location.pathname.startsWith("/login-tenant") ||
                        location.pathname.startsWith("/forgot") ||
                        location.pathname.startsWith("/forgot-tenant") ||
                        location.pathname.startsWith("/_auth") ||
                        location.pathname.startsWith("/reset-password")
                    );
                },
                renderToSelector: appOptions.selectors.unauthenticated,
                basePath: "/",
            };
        });
    },
});
