import { LogLevel } from "@manabie-com/mana-std-logger";

export function getLogLevelInSF() {
    const host = window.location.host;
    if (
        host.includes("staging-manabie") ||
        host.includes("stag-pm-manabie") ||
        host.includes("scratch.lightning.force.com")
    ) {
        return LogLevel.DEBUG;
    }

    return LogLevel.WARN;
}
