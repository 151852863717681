import { retry } from "@manabie-com/mana-utils";

export async function asyncForSequential<T, P>(
    arr: T[],
    callback: (item: T, index: number) => P
): Promise<P[]> {
    const results = [];
    for (let i = 0; i < arr.length; i++) {
        results.push(await callback(arr[i], i));
    }
    return results;
}

export async function asyncForParallel<T, P>(
    arr: T[],
    callback: (item: T, index: number) => Promise<P>
): Promise<P[]> {
    const promises = [];
    for (let i = 0; i < arr.length; i++) {
        promises.push(callback(arr[i], i));
    }
    return Promise.all(promises);
}

export function importWithRetry(moduleId: string) {
    return retry(() => import(/* @vite-ignore */ moduleId), 3, 2000);
}
