import { getMountedApps, navigateToUrl, registerApplication, start } from "single-spa";

import { createModuleContainer } from "../packages/create-module-container";
import type {
    AddTeamOptions,
    AppDOMSelector,
    BootstrapOptions,
    ChildAppProps,
    ModuleContainer,
    RegisterManaAppConfig,
    YourTeamProps,
} from "./core-types";
import { importWithRetry } from "./core-utils";

import type { CustomProps as SpaCustomProps } from "single-spa";

function registerToSpa<AppAdditionalProps extends SpaCustomProps>(
    app: RegisterManaAppConfig<AppAdditionalProps>,
    options: BootstrapOptions<AppDOMSelector, YourTeamProps>,
    moduleContainer: ModuleContainer
) {
    const customProps = {
        propsDefinedInRoot: {
            moduleContainer,
            renderToSelector: app.renderToSelector,
            selectors: options.selectors,
            basePath: app.basePath,
            ...options.additionalProps,
        },
        propsDefinedByYourTeam: app.additionalProps,
    };

    registerApplication<ChildAppProps<AppAdditionalProps>>({
        name: app.name,
        app: () => app.app(),
        activeWhen: app.activeOnPath,
        customProps: customProps,
    });

    // if an application is preferred
    // we need to load it before all the check occur so that it can be mounted immediately
    // we assume that the app loader function is pure (side effect free)
    if (app.isPreferred) {
        if (typeof requestIdleCallback !== "undefined") {
            requestIdleCallback(() => {
                void app.app();
            });
        } else {
            void app.app();
        }
    }

    options.hooks.onAppRegistered?.(app.name);
}

function createEventListenRouteChange({
    appConfigs,
}: {
    appConfigs: Record<string, { renderToSelector: string; selectors: AppDOMSelector }>;
}) {
    window.addEventListener("single-spa:before-mount-routing-event", () => {
        const loadingGlobal = document.querySelector("#global-page-loading");
        // show global loading
        if (loadingGlobal && loadingGlobal.classList.contains("mfe-loading-global-hidden")) {
            loadingGlobal.classList.remove("mfe-loading-global-hidden");
        }
    });

    window.addEventListener("single-spa:routing-event", () => {
        if (!window.location.pathname || window.location.pathname === "/") return;

        const mountedApps = getMountedApps();
        window.warner?.log("single-spa finished mounting/unmounting applications!", mountedApps);

        // hide global loading
        const loadingGlobal = document.querySelector("#global-page-loading");
        if (loadingGlobal && !loadingGlobal.classList.contains("mfe-loading-global-hidden")) {
            loadingGlobal.classList.add("mfe-loading-global-hidden");
        }

        for (const appName of mountedApps) {
            const appConfig = appConfigs[appName];
            if (typeof appConfig === "undefined") continue;

            // if there are any app mount in main/undefined selector, we should not redirect to 404 page
            if (
                appConfig.renderToSelector === appConfig.selectors.main ||
                appConfig.renderToSelector === appConfig.selectors.unauthenticated
            ) {
                return;
            }
        }

        navigateToUrl("/page-not-found");
    });
}

export function createRootShell<Selectors extends AppDOMSelector, Props extends YourTeamProps>(
    shellOptions: BootstrapOptions<Selectors, Props>
) {
    const injector = shellOptions.getInjector();
    let appConfigs: Record<string, { renderToSelector: string; selectors: Selectors }> = {};

    shellOptions.hooks.onInjectorReady?.(injector);

    const context = {
        injector,
        getMountedApps: () => {
            return getMountedApps();
        },
    };
    const container = createModuleContainer(context);

    async function loadSetupModules() {
        await container.loadAll();

        shellOptions.hooks.onAllModuleLoaded?.();
    }

    async function registerAppFn<AppAdditionalProps extends SpaCustomProps>(
        defineApp: () => RegisterManaAppConfig<AppAdditionalProps>
    ) {
        const app = defineApp();

        registerToSpa(app, shellOptions, container);

        appConfigs[app.name] = {
            renderToSelector: app.renderToSelector,
            selectors: shellOptions.selectors,
        };
    }

    return {
        addTeam(team: AddTeamOptions<Selectors, Props>) {
            team.registerApps?.(registerAppFn, importWithRetry, {
                additionalProps: shellOptions.additionalProps,
                selectors: shellOptions.selectors,
            });

            team.setupModules?.forEach((module) => {
                container.addModule(module);
            });
        },
        async startApp() {
            return loadSetupModules()
                .then(async () => {
                    return await container.onBeforeStart();
                })
                .then(async () => {
                    createEventListenRouteChange({
                        appConfigs,
                    });

                    start({
                        urlRerouteOnly: true,
                    });

                    await container.loadAll();
                    shellOptions.hooks.onShellStarted?.();
                    await container.onAfterStart();
                })
                .catch((e) => {
                    shellOptions.hooks.onShellError?.(e);
                    // retry to catch outside
                    throw e;
                });
        },
    };
}
