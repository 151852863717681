import { addTeam } from "../run";

addTeam({
    setupModules: [
        {
            moduleId: "timesheet:init-module",
            enforce: "post",
            loader(importWithRetry) {
                return importWithRetry("@manabie-com/mfe/timesheet-init-module");
            },
            onError(_err: unknown) {},
        },
    ],
    teamName: "timesheet",
    registerApps(register, importWithRetry, appOptions) {
        register(() => {
            return {
                name: "timesheet-main",
                app: () => importWithRetry("@manabie-com/mfe/timesheet-main"),
                activeOnPath: (location) => {
                    return new RegExp(/^\/timesheet(\/.*)?$/).test(location.pathname);
                },
                renderToSelector: appOptions.selectors.authenticated,
                basePath: "/timesheet",
            };
        });
    },
});
