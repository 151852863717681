import type {
    MfeContext,
    ModuleContainer,
    ModuleDefinition,
    ModuleLifeCycle,
} from "../core/core-types";
import { asyncForParallel, asyncForSequential, importWithRetry } from "../core/core-utils";

export function getSortedModules(modules: readonly ModuleDefinition[]): ModuleDefinition[] {
    const pre: ModuleDefinition[] = [];
    const normal: ModuleDefinition[] = [];
    const post: ModuleDefinition[] = [];
    for (const module of modules) {
        if (typeof module === "object") {
            if (module.enforce === "pre") {
                pre.push(module);
                continue;
            }
            if (module.enforce === "post") {
                post.push(module);
                continue;
            }
        }
        normal.push(module);
    }
    return [...pre, ...normal, ...post];
}

type _InternalStateKeys =
    | "onLoad"
    | "onBeforeStart"
    | "onAfterStart"
    | "onAuthenticated"
    | "onUnauthenticated"
    | "onDestroy";

interface _InternalState {
    promise: Promise<unknown> | null;
    isLoaded: boolean;
}

export function createModuleContainer(context: MfeContext): ModuleContainer {
    const moduleRegistry = new Map<string, ModuleDefinition>();
    let moduleLifeCycle: ModuleLifeCycle[] = [];

    const _internalState: Record<_InternalStateKeys, _InternalState> = {
        onLoad: { isLoaded: false, promise: null },
        onUnauthenticated: { isLoaded: false, promise: null },
        onAuthenticated: { isLoaded: false, promise: null },
        onBeforeStart: { isLoaded: false, promise: null },
        onAfterStart: { isLoaded: false, promise: null },
        onDestroy: { isLoaded: false, promise: null },
    };

    return {
        addModule(moduleDef: ModuleDefinition) {
            if (_internalState.onLoad.isLoaded) {
                throw new Error(`All modules loaded, cannot add more. ${moduleDef.moduleId}`);
            }

            if (moduleRegistry.has(moduleDef.moduleId)) {
                throw new Error(`Duplicated module id: "${moduleDef.moduleId}"`);
            }

            moduleRegistry.set(moduleDef.moduleId, moduleDef);
        },
        async loadAll() {
            if (_internalState.onLoad.promise instanceof Promise) {
                return _internalState.onLoad.promise;
            }

            const sortedModules: ModuleDefinition[] = getSortedModules([
                ...moduleRegistry.values(),
            ]);

            _internalState.onLoad.promise = asyncForParallel(sortedModules, async (module) => {
                return await module.loader(importWithRetry);
            });

            return _internalState.onLoad.promise.then((val) => {
                moduleLifeCycle = val as ModuleLifeCycle[];
                _internalState.onLoad.isLoaded = true;
            });
        },
        async onBeforeStart() {
            if (_internalState.onBeforeStart.promise instanceof Promise) {
                return _internalState.onBeforeStart.promise;
            }

            _internalState.onBeforeStart.promise = asyncForSequential(
                moduleLifeCycle,
                async (module) => {
                    if (module.onBeforeStart) {
                        return await module.onBeforeStart(context);
                    }
                }
            );

            return _internalState.onBeforeStart.promise.then((val) => {
                _internalState.onBeforeStart.isLoaded = true;

                return val;
            });
        },
        async onAfterStart() {
            if (_internalState.onAfterStart.promise instanceof Promise) {
                return _internalState.onAfterStart.promise;
            }

            _internalState.onAfterStart.promise = asyncForParallel(
                moduleLifeCycle,
                async (module) => {
                    if (module.onAfterStart) {
                        await module.onAfterStart(context);
                    }
                }
            );

            return _internalState.onAfterStart.promise.then((val) => {
                _internalState.onAfterStart.isLoaded = true;

                return val;
            });
        },
        async onAuthenticated() {
            if (_internalState.onAuthenticated.promise instanceof Promise) {
                return _internalState.onAuthenticated.promise;
            }

            _internalState.onAuthenticated.promise = asyncForSequential(
                moduleLifeCycle,
                async (module) => {
                    if (module.onAuthenticated) {
                        await module.onAuthenticated(context);
                    }
                }
            );

            return _internalState.onAuthenticated.promise.then((val) => {
                _internalState.onAuthenticated.isLoaded = true;

                return val;
            });
        },
        async onUnauthenticated() {
            if (_internalState.onUnauthenticated.promise instanceof Promise) {
                return _internalState.onAfterStart.promise;
            }

            _internalState.onUnauthenticated.promise = asyncForParallel(
                moduleLifeCycle,
                async (module) => {
                    if (module.onUnauthenticated) {
                        await module.onUnauthenticated(context);
                    }
                }
            );

            return _internalState.onUnauthenticated.promise.then((val) => {
                _internalState.onUnauthenticated.isLoaded = true;

                return val;
            });
        },
        destroy() {
            if (_internalState.onDestroy.promise instanceof Promise) {
                return _internalState.onDestroy.promise;
            }

            _internalState.onDestroy.promise = asyncForParallel(moduleLifeCycle, async (module) => {
                if (module.destroy) {
                    await module.destroy();
                }
            });

            return _internalState.onDestroy.promise.then((val) => {
                _internalState.onDestroy.isLoaded = true;

                return val;
            });
        },
    };
}
