import { addErrorHandler, getAppStatus } from "single-spa";
import { manaGlobals } from "src/only-for-temporary/mana-globals";
import { ulid } from "ulid";

import { enableLogger } from "./internals/warner";
import { startApp } from "./run/run";

import type { PjOwner } from "src/only-for-temporary/org-apis/org-APIs-types";

import.meta.glob("./run/teams/*.ts", { eager: true });

if (!window.__MANA__SESSION_ID__) {
    window.__MANA__SESSION_ID__ = ulid();
}

if (!window.__MANA__) {
    window.__MANA__ = {} as any;
}

window.__MANA__.isMultiTenant = import.meta.env.VITE_PJ_OWNER !== "jprep";
window.__MANA__.defaultPjOwner = import.meta.env.VITE_PJ_OWNER
    ? (import.meta.env.VITE_PJ_OWNER as PjOwner)
    : "manabie";

addErrorHandler((err) => {
    window.warner?.warn("[single-spa]", err, "status", getAppStatus(err.appOrParcelName));
});

enableLogger();

startApp()
    .then(() => {
        const sidebarOpenedClass = "sidebar-opened";
        const eventEmitter = manaGlobals.eventEmitter();
        eventEmitter.subscribe("architecture:sidebar:mount", (data) => {
            if (data.payload) {
                const main = document.querySelector("#main");
                if (!main) return;
                const cls = "sidebar-mounted";

                if (data.payload?.state === "mounted") {
                    main.classList.add(cls);
                    if (data.payload.isOpen) {
                        main.classList.add(sidebarOpenedClass);
                    }
                } else {
                    main.classList.remove(cls);
                }

                // Taskbar
                const taskBar = document.querySelector("#taskbar");
                if (!taskBar) return;

                if (data.payload?.state === "mounted") {
                    taskBar.classList.add(cls);
                    if (data.payload.isOpen) {
                        taskBar.classList.add(sidebarOpenedClass);
                    }
                } else {
                    taskBar.classList.remove(cls);
                }
            }
        });
        eventEmitter.subscribe("architecture:sidebar", (data) => {
            if (data.payload) {
                const main = document.querySelector("#main");
                if (!main) return;

                if (data.payload?.open === true) {
                    main.classList.add(sidebarOpenedClass);
                } else {
                    main.classList.remove(sidebarOpenedClass);
                }

                const taskBar = document.querySelector("#taskbar");
                if (!taskBar) return;

                if (data.payload?.open === true) {
                    taskBar.classList.add(sidebarOpenedClass);
                } else {
                    taskBar.classList.remove(sidebarOpenedClass);
                }
            }
        });

        eventEmitter.subscribe("architecture:appbar:mount", (data) => {
            if (data.payload) {
                const main = document.querySelector("#main");
                if (!main) return;
                const cls = "appbar-mounted";

                if (data.payload?.state === "mounted") {
                    main.classList.add(cls);
                } else {
                    main.classList.remove(cls);
                }

                const taskBar = document.querySelector("#taskbar");
                if (!taskBar) return;

                if (data.payload?.state === "mounted") {
                    taskBar.classList.add(cls);
                } else {
                    taskBar.classList.remove(cls);
                }
            }
        });
    })
    .catch((reason) => {
        console.error(reason);
    });
